
body{
  font-family: 'Jost', sans-serif !important;
}

.date-picker-custom-selected .react-datepicker__day--keyboard-selected {
  background: #dee2e6;
  color: black;
}
.date-picker-custom-selected .react-datepicker__day--disabled {
  background-color: rgba(209, 55, 45, 0.5019607843);
  color: #7d211b;
}