// Cyborg 5.1.3
// Bootswatch

// Variables

$web-font-path: "https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700&display=swap" !default;
@if $web-font-path {
  @import url($web-font-path);
}

// Navbar

// table
$table-color: $gray-700;

.navbar {
  &.bg-primary {
    border: 1px solid $gray-700;
  }

  &.bg-dark {
    background-color: $body-bg !important;
  }

  &.bg-light {
    background-color: $gray-500 !important;
  }

  &.fixed-top {
    border-width: 0 0 1px;
  }

  &.fixed-bottom {
    border-width: 1px 0 0;
  }
}

// Buttons

.black-button {
  background-color: #000;
  color: #fff;
  padding: 0.5rem;
  border-radius: 0.5rem;
  &:hover{
    background-color: rgb(31, 31, 31);
  }
}

.btn {
  @each $color, $value in $theme-colors {
    &-#{$color} {
      @if $enable-gradients {
        background: $value
          linear-gradient(180deg, mix($white, $value, 15%), $value)
          repeat-x;
      } @else {
        background-color: $value;
      }
    }
  }
}
.btn-outline-dark.active {
  background-color: transparent;
  color: $white;
}
.btn-outline-dark :hover {
  background-color: transparent !important;
  color: $white;
}
// Forms

legend {
  color: $white;
}

.form-control {
  background-clip: border-box;

  &:disabled,
  &[readonly] {
    border-color: transparent;
  }
}

// Navs

.nav-tabs,
.nav-pills {
  .nav-link {
    color: $white;

    &:hover {
      background-color: $gray-700;
    }

    &.disabled,
    &.disabled:hover {
      background-color: transparent;
      color: $nav-link-disabled-color;
    }

    &.active {
      color: $orange;
      background-color: $primary;
    }
  }
}

.breadcrumb {
  a {
    color: $white;
  }
}

.pagination {
  a:hover {
    text-decoration: none;
  }
}

// Indicators

.alert {
  border: none;
  color: $white;

  a,
  .alert-link {
    color: $white;
    text-decoration: underline;
  }

  @each $color, $value in $theme-colors {
    &-#{$color} {
      @if $enable-gradients {
        background: $value
          linear-gradient(180deg, mix($white, $value, 15%), $value)
          repeat-x;
      } @else {
        background-color: $value;
      }
    }
  }
}

.badge {
  &.bg-dark {
    color: $gray-900;
  }
}

// Containers

.list-group-item-action {
  &:hover {
    border-color: $primary;
  }
}

.popover {
  &-title {
    border-bottom: none;
  }
}

.ss-dd-t {
  background-color: $white;
  max-width: 45rem;
  color: $black;
  border-color: $gray-500;
  &:hover {
    background-color: $white;
    color: $black;
  }
  &.dropdown-toggle:focus {
    background-color: $white;
    color: $black;
  }
}
.ss-dd-i {
  background-color: $white;
  color: $black;
  border-color: $gray-500;
  &:hover {
    background-color: $white;
    color: $black;
  }
  &.dropdown-toggle:focus {
    background-color: $white;
    color: $black;
  }
}
.ss-btn {
  background-color: $white;
  color: $black;
}
//persona style
.persona {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
}
.image-persona {
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 50%;
}
.modal-header .btn-close {
  background-color: $gray-500;
  color: $black;
}
.modal-title {
  color: $gray-700;
  font-weight: 500;
  font-size: 1.5rem;
}
.schedule-listgroup {
  background-color: $white;
  color: $black;
  border-color: $gray-400;
  &:hover {
    background-color: $gray-300;
    cursor: pointer;
  }
  &.active {
    background-color: $gray-300;
    color: $black;
  }
}
.bd-hours {
  height: 4rem;
  font-size: 1rem;
  font-weight: 600;
  text-align: right;
  line-height: 5rem;
  color: $gray-700;
  padding-right: 1rem;
  &:hover {
    cursor: pointer;
    background-color: $gray-300;
  }
}
.bd-hours-active {
  height: 4rem;
  font-size: 1rem;
  font-weight: 600;
  text-align: right;
  line-height: 5rem;
  padding-right: 1rem;
  background-color: $gray-700;
  color: $white;
}
.bd-hours-inactive {
  height: 4rem;
  font-size: 1rem;
  font-weight: 600;
  text-align: right;
  line-height: 5rem;
  background-color: $gray-500;
}
.close {
  color: #fff;
  opacity: 1;
}
.btn-crack {
  color: $white;
  border-color: $white;
}
.map-business-detail {
  border: 0.1rem solid $gray-300;
}
.schedule-form-check-input {
  border: 0.1rem solid $gray-400;
  border-radius: 50% !important;
  &:checked {
    border-radius: 50%;
    background-color: $orange !important;
    border-color: $orange !important;
  }
}
.accordion-button {
  position: relative;
  border-radius: 0;

  display: flex;
  align-items: center;
  width: 100%;
  padding: $accordion-button-padding-y $accordion-button-padding-x;
  @include font-size($font-size-base);
  color: $gray-700;
  text-align: left; // Reset button style
  background-color: $white;

  border: 0;
  @include border-radius(0);
  overflow-anchor: none;
  @include transition($accordion-transition);

  &:not {
    border: none;
    background-image: escape-svg($accordion-button-active-icon);
    transform: $accordion-icon-transform;
  }
  &:not(.collapsed) {
    border: none;
    color: $gray-700;
    background-color: $white;
    box-shadow: inset 0 ($accordion-border-width * -1) 0 $accordion-border-color;
  }

  &::after {
    border: none;
    background-image: escape-svg($accordion-button-active-icon);
  }
}
.accordion-body {
  background-color: $white;
  color: $gray-700;
  border: none;
}
.accordion-item {
  border: none;
  border-radius: 0;
}
.accordion-item:first-of-type .accordion-button {
  border-radius: 0;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: gray;
  pointer-events: none;
  background-color: $gray-300;
}
