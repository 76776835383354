//footer
.footer-main {
  background-color: $black;
  color: $white;
}
.footer-allright {
  padding-top: 3rem;
  font-size: 0.75rem;
}
.footer-email {
  text-decoration: none;
  color: $white;
  &:hover {
    color: $orange;
  }
}
//header
.header-addb {
  font-weight: 500;
  color: $orange !important;
}

.header-user-info {
  background: $black;
  color: $orange !important;
  & nav {
    color: $orange;
  }
}

.header-avatar {
  background: $white;
  width: 2rem;
  height: 2rem;
  padding: 0.1rem;
  border: 0.15rem solid $orange;
  border-radius: 50%;
}
.dropdown-btn-menu {
  border: 0.15rem solid $white;
  border-radius: 10%;
}
//top
.top-main {
  background-image: url("../assets/BannerImage.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: content-box;
}
.top-label {
  margin-top: 3.5rem;
  width: 100%;
  text-align: center;
  color: $orange;
  font-size: 2rem;
  font-weight: 600;
}
//signin screen
.ls-signup-text {
  text-decoration: underline;
  color: $dark;
  &:hover {
    color: $orange;
    cursor: pointer;
  }
}
.ls-form-main {
  max-width: 30rem;
}

//home screen

// top components styles
.top-slogan {
  font-size: 1rem;
}
.top-search {
  background: transparent;
  border: none;
  color: $white;
}

.top-input {
  max-width: 45rem;
  border: 0.1rem $white solid;
  border-radius: 0.5rem;
}
.top-search-button {
  background-color: $white;
  color: $black;
  font-weight: 500;
  &:hover {
    background-color: $gray-500;
    color: $black;
  }
}
.top-search-button-two {
  background-color: transparent;
  border-color: $white;
  color: $white;
  font-weight: 500;
  &:hover {
    background-color: transparent;
    border-color: $gray-500;
    color: $gray-500;
  }
}

.add-business-title {
  margin-top: 3rem;
  font-weight: 600;
}
.add-business-slogan {
  font-weight: 300;
}
.add-business-row {
  margin-top: 3rem;
}
.add-business-button {
  color: $white;
  border: $white 0.05rem solid;
  &:hover {
    background-color: $orange;
    color: $white;
  }
}
.addbusiness-card-img {
  width: 4.5rem;
  border: 0.15rem solid $white;
  border-radius: 50%;
  padding: 0.5rem;
}
.addbusiness-card-card {
  width: 12rem;
  background: transparent;
}
.addb-button {
  width: 8rem;
  height: 8rem;
  font-size: 1rem;
  &:focus {
    background-color: $orange;
    color: $white;
  }
}
.addbusiness-card-title {
  color: $white;
  font-size: 0.9rem;
  font-weight: 400;
}
.maphs-main {
  min-height: 30rem;
  background-image: url("../assets/Group95.png");
  background-position: center;
  background-repeat: no-repeat;

  background-size: cover;
}
.review-title {
  margin-top: 3rem;
  font-weight: 600;
}
.review-slogan {
  font-weight: 300;
}

.review-card-card {
  margin-top: 3rem;
  background: transparent;
  height: 20rem;
}
.review-card-img {
  position: relative;
  background-color: $white;
  top: -2.5rem;
  width: 6rem;
  border: 0.15rem solid $gray-400;
  border-radius: 50%;
  padding: 2%;
}
.review-card-title {
  color: $black;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
}
.review-card-name {
  color: $orange;
  font-size: 1rem;

  font-weight: 600;
  text-align: center;
}
.review-card-body {
  vertical-align: bottom;
}
.engage-title-span {
  color: $orange;
}
.engage-card-title {
  color: $white;
  font-size: 1.5rem;
  font-weight: 600;
}
.engage-card-text {
  color: $white;
  font-size: 1rem;
  font-weight: 400;
}
.engage-card {
  min-height: 20rem;
  max-height: 100%;
  background-color: transparent;
  color: $white;
  border: 0.15rem solid $white;
  border-radius: 1rem;
}
.engage-card-image {
  margin-top: 3rem;
  height: 4rem;
}
.engage-btn {
  background-color: transparent;
  color: $white;
  &:hover {
    background-color: transparent;
    color: $white;
  }
}
.servicess-card-img {
  width: 4.6rem;
  height: 4.6rem;
}

.servicess-card-title {
  font-size: 1rem;
  font-weight: 600;
  color: $black;
}

.servicess-card-text {
  font-size: 0.85rem;
  color: $orange;
}
.servicess-card-link {
  text-decoration: none;
  color: $black;
  &:hover {
    color: $orange;
  }
}
.card-main {
  background-color: $white;
  border: none;
  text-align: center;
  padding: 3rem;
}

//about us screen

.aus-continens-secundo {
  background-color: $orange;
}
.aus-button {
  max-width: 10rem;
  min-width: 7rem;
}
.aus-h2-black {
  color: $black;
  font-weight: 600;
}
.aus-h2-white {
  color: $white;
  font-weight: 600;
}

//contact us screen

.cus-contactus-notitia {
  background-color: $orange;
}
.cus-contactus-text {
  color: $white;
}
.cus-contactus-email {
  text-decoration: none;
  color: $white;
  &:hover {
    color: $white;
  }
}
.cus-contact-form {
  color: $black;
  max-width: 40rem;
}

//services screen

.sl-card-img {
  background: black;
  border: 0.1rem solid $white;
  border-radius: 50%;
  padding: 0.4rem;
  width: 3rem;
  height: 3rem;
}
.sl-card-img-orange {
  background: $orange;
  border: 0.1rem solid $white;
  border-radius: 50%;
  padding: 0.4rem;
  width: 3rem;
  height: 3rem;
}
.ser-card {
  text-decoration: none;
  color: $black;
  &:hover {
    color: $orange;
  }
}
.sc-image {
  width: auto;
  max-height: 10rem;
}

.sc-card {
  background-color: $white;
  height: 25rem;
}

.bd-title {
  font-size: 1.2rem;
  font-weight: 600;
}
.bd-text {
  font-size: 1.1rem;
  font-weight: 400;
}
.slt-img-b {
  height: 3.4rem;
  width: 3.4rem;
  border: 0.15rem solid $white;
  border-radius: 50%;
  padding: 0.5rem;
  background-color: transparent;
  &:hover {
    background-color: $orange;
  }
}
.slt-img-w {
  height: 3.4rem;
  width: 3.4rem;
  border: 0.15rem solid $white;
  border-radius: 50%;
  padding: 0.5rem;
  background-color: $white;
}
// animation
.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

//dashboards
//user
.menu-btn {
  background-color: $gray-300;
  border: 0.15rem solid $gray-300;
  color: $gray-700;
  &:hover {
    cursor: pointer;
    background-color: $gray-200;
    color: $gray-700;
    border: 0.15rem solid $gray-300;
  }

  &.active {
    background-color: $white;
    border: 0.15rem solid $gray-300;
    color: $gray-700;
    box-shadow: none;
  }
  &.active:focus {
    background-color: $white;
    border: 0.15rem solid $gray-300;
    color: $gray-700;
    box-shadow: none;
  }

  &:focus {
    background-color: $white;
    border: 0.15rem solid $gray-300;
    color: $gray-700;
    box-shadow: none;
  }
}
.avatar-user-manager {
  width: 5rem;
  height: 5rem;
  padding: 0.2rem;
  border: 0.15rem solid $orange;
  border-radius: 50%;
}
.btn-text-dashboard-user {
  color: $btnColor;
}
.sgnup-form-label {
  color: $gray-400;
  font-size: 0.8rem;
  font-weight: 500;
}
.db-business-manager.dropdown-menu.show {
  background-color: $white !important;
}

.table-curved {
  border-collapse: collapse;
  margin-left: 1rem;
}

.table-curved td {
  position: relative;
  background-color: white;
  margin-left: 1rem;
  padding: 6px 10px;
  border-bottom: 0.3rem solid white;
  border-top: 0.3rem solid white;
  &:hover {
    background-color: $gray-300;
  }
}
.tap-button {
  background: $gray-300;
  width: auto;
  border: none;
  color: $gray-700;
  padding: 0.5rem;
  margin-right: 1rem;
  border-radius: 0.3rem;
  font-size: 0.8rem;
  &:hover {
    cursor: pointer;
    background: $orange;
  }
}
.tap-button-active {
  width: auto;
  background: $orange;
  border: none;
  color: $gray-700;
  padding: 0.5rem;
  margin-right: 1rem;
  border-radius: 0.3rem;
  font-size: 0.8rem;
  &:hover {
    cursor: pointer;
    background: $orange;
  }
}
.modal-inactive {
  background-color: none;
}
.modal-active {
  background-color: white;
  opacity: 0.6;
  pointer-events: none;
}
.border-error {
  border: 1px solid red;
}

@include media-breakpoint-up(xs) {
  .resp-par-text {
    font-size: 0.9rem;
  }
  .resp-text-title {
    text-align: center;
    padding-left: 0.5rem;
  }
  .resp-rating {
    justify-content: center;
  }
  .resp-carousel-image {
    height: 15rem;
  }
  .resp-carousel-image-single {
    max-height: 15rem;
  }
  .schedule-hours-preview {
    justify-content: center;
  }
}
@include media-breakpoint-up(md) {
  .resp-par-text {
    font-size: 1.3rem;
  }
  .resp-text-title {
    text-align: start;
    padding-left: 0.5rem;
  }
  .resp-rating {
    justify-content: start;
  }
  .resp-carousel-image {
    height: 30rem;
  }
  .resp-carousel-image-single {
    max-height: 30rem;
  }
  .schedule-hours-preview {
    justify-content: center;
  }
}
@include media-breakpoint-up(xl) {
  .resp-par-text {
    font-size: 1rem;
  }
  .resp-text-title {
    text-align: start;
    padding-left: 0.5rem;
  }
  .resp-rating {
    justify-content: start;
  }
  .resp-carousel-image {
    height: 30rem;
  }
  .resp-carousel-image-single {
    max-height: 30rem;
  }
  .schedule-hours-preview {
    justify-content: start;
  }
}
